<template>
  <div>
    <kn-form-subtitle :title="`${actionTitle} estatus de ingreso`" />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col 
          class="pb-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4">
          <v-subheader>Título:</v-subheader>
        </v-col>
        <v-col class="pb-0">
          <v-text-field
            v-model="incomeStatus.dato"
            dense
            outlined
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <kn-form-action-buttons
        class="mb-14"
        :small="true"
        :disableAccept="invalidFields"
        @accept="save"
        @cancel="clear"
      />
    </v-form>
    <kn-form-subtitle
      title="Listado de estatus de ingreso"
      divider-position="bottom"
    />
    <kn-list-with-actions
      :activeItems="activeIncomeStatus"
      @updateInline="updateInline"
      @deleteInline="deleteInline"
      @cancelInline="clearInline"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { api } from '@/api/axios-base'
import { validationFormMixin } from '@/mixins/validationFormMixin'
export default {
  components: {
    KnFormSubtitle: () => import('@/components/KnFormSubtitle.vue'), 
    KnFormActionButtons: () => import('@/components/KnFormActionButtons.vue'),
    KnListWithActions: () => import('./KnListWithActions.vue')
  },
  mixins: [validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      incomeStatus: {
        id: 'NA', // No asignado
        dato: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      status: [],
      toUpdate: false
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Agregar'
    },
    activeIncomeStatus() {
      return this.status.filter(st => st.estatus_sistema === true)
    },
    invalidFields() {
      return this.objectHasNulls(this.incomeStatus)
    }
  },
  async created() {
    this.incomeStatus.id_autor = this.userData.id
    this.incomeStatus.id_institucion_educativa = this.institutionId
    this.status = await this.fetchIncomeStatusByEI(this.institutionId)

  },
  methods: {
    async fetchIncomeStatusByEI(institutionId) {
      try {
        const response = await api.get(`/administracion/api/estatus-ingreso-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener estatus de ingreso por institucion educativa', error);
      }
    },
    async createIncomeStatus() {
      try {
        const response = await api.post('/administracion/crear-estatus-ingreso', {
          estatus_ingreso: this.incomeStatus.dato,
          id_autor: this.incomeStatus.id_autor,
          id_institucion_educativa: this.incomeStatus.id_institucion_educativa
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo crear el estatus de ingreso.')
        }
      } catch (error) {
        console.error('Error al intentar crear estatus de ingreso', error)
        this.errors.push('Error al intentar crear estatus de ingreso')
      }
    },
    async updateIncomeStatus() {
      try {
        const response = await api.patch('/administracion/update-estatus-ingreso', {
          id: this.incomeStatus.id,
          estatus_ingreso: this.incomeStatus.dato,
          id_institucion_educativa: this.institutionId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo actualizar el estatus de ingreso')
        }
      } catch (error) {
        console.error('Error al intentar actualizar estatus de ingreso', error);
      }
    },
    // Los deletes no eliminan los registros, cambian el estatus a False
    async deleteIncomeStatus(typeId) {
      try {
        const response = await api.post('/administracion/delete-estatus-ingreso', {
          id_estatus_ingreso: typeId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo eliminar el estatus de ingreso')
        }
      } catch (error) {
        console.error('Error al intentar eliminar estatus de ingreso', error);
      }
    },
    async save() {
      await this.createIncomeStatus()
      this.status = await this.fetchIncomeStatusByEI(this.institutionId)
      this.clear()
    },
    clear() {
      this.incomeStatus = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    clearInline() {
      this.incomeStatus = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    async updateInline(item) {
      //console.log('updateInline item', item);
      this.incomeStatus = {...item}
      await this.updateIncomeStatus()
      this.status = await this.fetchIncomeStatusByEI(this.institutionId)
      this.clearInline()
    },
    async deleteInline(itemId) {
      await this.deleteIncomeStatus(itemId)
      this.status = await this.fetchIncomeStatusByEI(this.institutionId)
      this.clearInline()
    }
  },
}
</script>

<style>

</style>